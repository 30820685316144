import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ProfileProps from "./ProfileProps";

const Profile = loadable(
    (): Promise<DefaultComponent<ProfileProps>> =>
        import(/* webpackChunkName: "Profile" */ "./Profile"),
);
const ProfileLoader = (props: ProfileProps): ReactElement => (
    <Profile {...props} />
);
export default ProfileLoader;
