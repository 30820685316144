import React, { ReactElement } from "react";

import Profile from "components/Profile";
import Section from "components/Section";
import { useIsInEditMode } from "hooks";
import { addEditAttributes } from "utils/episerver";

import { Profiles, Heading, Container } from "./ProfileListSection.styled";
import ProfileListSectionProps from "./ProfileListSectionProps";

const ProfileListSection = ({
    identifier,
    items,
    className,
    heading,
    anchorName,
    theme,
}: ProfileListSectionProps): ReactElement => {
    const isInEditMode = useIsInEditMode();

    return (
        <Section theme={theme} id={anchorName} alignItems="center">
            <Container
                key={identifier || `ProfileListSectionContent`}
                className={className}
                id={anchorName}
            >
                {(isInEditMode || heading) && (
                    <Heading {...addEditAttributes("Heading")}>
                        {heading}
                    </Heading>
                )}
                {items && items.length > 0 && (
                    <Profiles>
                        {items?.map((item, index) => {
                            if (item.url) delete item.url;
                            if (item.description) delete item.description;
                            return (
                                <Profile key={`Profile${index}`} {...item} />
                            );
                        })}
                    </Profiles>
                )}
            </Container>
        </Section>
    );
};

export default React.memo(ProfileListSection);
