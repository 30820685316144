import { styled } from "styled-components";

import { Container as GridContainer } from "style/components/Page";
import { styleHeadingL } from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const Container = styled(GridContainer)`
    ${columnGap};
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr);
    padding-bottom: 4.375rem;
    padding-top: 2.1875rem;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
        padding-bottom: 6.125rem;
        padding-top: 3.5rem;
    }

    ${MQ.FROM_XL} {
        padding-bottom: 8.75rem;
        padding-top: 4.375rem;
    }
`;

export const Heading = styled.h2`
    ${styleHeadingL};
    grid-column: col-start 1 / span 3;
    margin-bottom: 2.1875rem;
    margin-top: 0;

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 8;
        margin-bottom: 3.5rem;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 1 / span 4;
    }

    ${MQ.FROM_XL} {
        margin-bottom: 4.375rem;
    }
`;

export const Profiles = styled.div`
    display: grid;
    grid-column: col-start 1 / span 4;
    grid-template-columns: repeat(1, [col-start] 1fr);
    ${columnGap};
    row-gap: 2.1875rem;

    ${MQ.FROM_M} {
        grid-column: col-start 4 / span 9;
        row-gap: 3.5rem;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 3 / span 10;
        grid-template-columns: repeat(2, [col-start] 1fr);
    }

    ${MQ.FROM_XL} {
        grid-column: col-start 3 / span 10;
        row-gap: 4.375rem;
    }
`;
